
body .ui-accordion .quarter .ui-accordion-header  a{
    background-color:#459BC3;
    color: white;
}


.grecaptcha-badge {
	visibility: collapse !important;
	display:none !important;
}
