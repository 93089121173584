.content {
  // background-color: #fff; padding: 20px; border-radius: 8px; opacity: 0.9;
}
body {
  // background-image: url('/assets/images/background.jpg'); background-position: top center; background-repeat: no-repeat; background-size: cover;
}

// @media (min-width: 1600px) {
//   .container {
//     width: 1570px;
//   }
// }

.navbar {

}

.navbar>.container {
  background: rgba(255, 255, 255, 0.8);
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;

  .form-signin-heading, .checkbox {
    margin-bottom: 10px;
  }
  .checkbox {
    font-weight: normal;
  }
  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;

    :focus {
      z-index: 2;
    }
  }

  input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
